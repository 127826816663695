var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "side-trans-enabled sidebar-dark",
    class: {
      'sidebar-o-xs': _vm.mobileMenu,
      'sidebar-o': _vm.menu
    },
    attrs: {
      "id": "page-container"
    }
  }, [_c('div', {
    attrs: {
      "data-simplebar": "init"
    }
  }), _c('Navbar', {
    on: {
      "onToggleMenu": _vm.onToggleMobileMenu
    }
  }), _c('Header', {
    on: {
      "onToggleMenu": _vm.onToggleMenu
    }
  }), _c('main', {
    attrs: {
      "id": "main-container"
    }
  }, [_vm._m(0), _c('router-view')], 1)], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "alert alert-warning alert-dismissible",
    attrs: {
      "id": "alert-container"
    }
  }, [_c('i', {
    staticClass: "fa fa-exclamation-triangle mr-1"
  }), _vm._v(" We are currently rolling out a series of upgrades which may temporarily impact the performance of some features. If you require any urgent assistance, please contact us. ")]);
}]

export { render, staticRenderFns }